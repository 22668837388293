import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import Patient from "./components/Patient"
import Reject from "./components/Reject"

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Patient />
        </Route>
        <Route path="/reject">
          <Reject />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
