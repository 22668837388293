import cloudfunctions from "./cloudfunctions.json";
import axios from "axios";

const isProduction = process.env.REACT_APP_STAGE === "prod";

const endpoints = isProduction
  ? cloudfunctions.production
  : cloudfunctions.development;

const api = {
  getAppointmentStatus: async (id) => {
    try {
      const res = await axios.post(endpoints.appointmentValidation, {
        id,
      });
      const status = res.data.status;
      const redirectURL = res.data.url;

      return { status, redirectURL };
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default api;
