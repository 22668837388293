import React from "react";

import Logo from "../assets/logo/Logo.png";

const Reject = () => {
  return (
    <div className="flex justify-center items-center min-h-screen max-w-screen-sm mx-auto px-2 sm:px-4">
      <div className="p-4 sm:p-8 flex flex-col items-center justify-center sm:shadow-xl text-center">
        <img src={Logo} className="my-4" alt="DOHMH logo" />
        <h2 className="text-center text-xl mb-4">
          Your video visit meeting room will not be ready until your appointment
          time.
        </h2>
        <p className="text-lg sm:mx-6 my-4">
          Please close this window and reopen the meeting room at your video
          visit appointment time.
        </p>
      </div>
    </div>
  );
};

export default Reject;
